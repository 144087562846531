import { Component } from '@angular/core';

@Component({
  selector: 'app-training-registration',
  templateUrl: './training-registration.component.html',
  styleUrls: ['./training-registration.component.scss']
})
export class TrainingRegistrationComponent {

}
