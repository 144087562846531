<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Admissions</li>
            </ul>
            <h2>Circular</h2>
        </div>
    </div>
</div>
<div class="calendar">
    <h1 style="margin-top: 30px; text-align: center; color: rgb(243, 24, 24);">Administration Circular!!</h1>
    <p>
        We are pleased to announce the release of the latest administration circular. This circular contains important updates and guidelines that will help streamline our operations and enhance the learning experience at the TelOne Center for Learning. We encourage all staff and students to review the circular to stay informed about the new policies and procedures.
    </p>
    <div class="word">
      <iframe src="/assets/pdf/admin2.pdf" width="100%" height="600px"></iframe>
      <iframe src="/assets/pdf/admin1.pdf" width="100%" height="600px"></iframe>
  </div>
</div>
