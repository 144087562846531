<app-header-style-one></app-header-style-one>

<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/content/content-image-3.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                               <a href="https://zwapplicant.edmatix.com/?orgcode=zwtelone/applicant" class="butt">Register</a>
                            </div>
                            <h3>Register For Your Degree</h3>
                            <br>
                            <br>
                            <br>
                            <p>
                                Unlock the doors to academic excellence with TelOne! Register today for our degree programs and embark on a transformative educational experience.
                               <br>
                               <br>
                                <h4><b>Why study with us</b></h4>
                                <br>
                                <br>
                                <p>
                                    TelOne centre for learning offers ICT and soft skills courses to help you succeed in your career. With state-of-the-art facilities and skilled staff, they provide the knowledge and skills you need without breaking the bank. Affordable tuition fees ensure you get the training you need.
                                 </p>
                                 <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>