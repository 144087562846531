<section class="information-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="information-image text-center">
                    <img src="assets/img/information-img.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title">Information</span>
                    <h2>How To Apply?</h2>
                    <ul class="apply-details">
                        <li>
                            <div class="icon">
                                <i class="flaticon-checkmark"></i>
                            </div>
                            <h3>Select Suitable Course</h3>
                            <p>
                                At Telone, we understand that choosing the right course is pivotal to your success. We're thrilled to offer a diverse range of courses tailored to meet your learning goals and elevate your skills.  
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-login"></i>
                            </div>
                            <h3>Student Information</h3>
                            <p>
                                At Telone Learning Center, we believe in recognizing and celebrating your achievements. As a top applicant, we want to ensure you have all the information you need to excel in your educational journey with us.
                                </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-credit-card"></i>
                            </div>
                            <h3>Payment Information</h3>
                            <p>
                                At Telone Learning Center, we strive to make your education journey seamless and accessible. To facilitate the payment process, we offer flexibility in payment methods and provide clear instructions for your convenience.
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Register Now</h3>
                            <p>Ready to embark on an exciting educational journey that will shape your future? Register with Telone Learning Center today and take the first step toward your dream career.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>