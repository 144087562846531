<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="courses-2-columns-style-1.html">Courses</a></li>
                <li>Certified Graphic Design with Free Project Course</li>
            </ul>
            <h2>Courses</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Certified Graphic Design with Free Project Course</h2>
                        <p>Embark on your journey in graphic design with our certified course, where learning meets creativity. Dive into exciting projects, unleash your imagination, and watch your skills flourish.</p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a routerLink="/single-courses">Design</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Students Enrolled</span>
                                <a routerLink="/single-courses">813,454</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">01/14/2019</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>
                        <div class="price">$250</div>
                        <a routerLink="/single-courses" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="assets/img/courses/where-you-study-matters.jpg" alt="image">
                </div>
                <div class="courses-details-desc">
                    <h3>What you'll learn</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Become an expert in Statistics
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Boost your resume with skills
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Gather, organize, data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use data for improved
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Present information KPIs
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Perform quantitative
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Analyze current data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Discover how to find trends
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Understand the fundamentals
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use SQL to create, design
                                </span>
                            </li>
                        </ul>
                    </div>
                    <h3>Requirements</h3>
                    <ul class="requirements-list">
                        <li>Access to Computer</li>
                        <li>Basic Computer Skills</li>
                        <li>Desire to Learn and Create</li>
                    </ul>
                    <h3>Description</h3>
                    <p><strong>Hi! Welcome to Certified Graphic Design with Free Project Course, the only course you need to become a BI Analyst.</strong></p>
                    <p>We are proud to present you this one-of-a-kind opportunity. There are several online courses teaching some of the skills related to the BI Analyst profession. The truth of the matter is that none of them completely prepare you.</p>
                    <p><strong>Our program is different than the rest of the materials available online.</strong></p>
                    <p>It is truly comprehensive. The Business Intelligence Analyst Course comprises of several modules:</p>
                    <ul class="description-features-list">
                        <li>Introduction to Data and Data Science</li>
                        <li>Statistics and Excel</li>
                        <li>Database theory</li>
                        <li>SQL</li>
                        <li>Tableau</li>
                    </ul>
                    <p>These are the precise technical skills recruiters are looking for when hiring BI Analysts. And today, you have the chance of acquiring an invaluable advantage to get ahead of other candidates. This course will be the secret to your success. And your success is our success, so let’s make it happen!</p>
                    <p>Here are some more details of what you get with The Business Intelligence Analyst Course:</p>
                    <ul class="description-features-list">
                        <li><strong>Introduction to Data and Data Science</strong> – Make sense of terms like business intelligence, traditional and big data, traditional statistical methods, machine learning, predictive analytics, supervised learning, unsupervised learning, reinforcement learning, and many more;</li>
                        <li><strong>Statistics and Excel</strong> – Understand statistical testing and build a solid foundation. Modern software packages and programming languages are automating most of these activities, but this part of the course gives you something more valuable – critical thinking abilities;</li>
                        <li><strong>Database theory</strong> – Before you start using SQL, it is highly beneficial to learn about the underlying database theory and acquire an understanding of why databases are created and how they can help us manage data;</li>
                        <li><strong>SQL</strong> – when you can work with SQL, it means you don’t have to rely on others sending you data and executing queries for you. You can do that on your own. This allows you to be independent and dig deeper into the data to obtain the answers to questions that might improve the way your company does its business;</li>
                        <li><strong>Tableau</strong> – one of the most powerful and intuitive data visualization tools available out there. Almost all large companies use such tools to enhance their BI capabilities. Tableau is the #1 best-in-class solution that helps you create powerful charts and dashboards;</li>
                        <li>Learning a programming language is meaningless without putting it to use. That’s why we integrate SQL and Tableau, and perform several real-life Business Intelligence tasks;</li>
                    </ul>
                    <p><strong>Sounds amazing, right?</strong></p>
                    <p>Our courses are unique because our team works hard to:</p>
                    <ul class="description-features-list">
                        <li>Pre-script the entire content</li>
                        <li>Work with real-life examples</li>
                        <li>Provide easy to understand and complete explanation</li>
                        <li>Create beautiful and engaging animations</li>
                        <li>Prepare exercises, course notes, quizzes, and other materials that will enhance your course taking experience</li>
                        <li>Be there for you and provide support whenever necessary</li>
                    </ul>
                    <p>We love teaching and we are really excited about this journey. It will get your foot in the door of an exciting and rising profession. Don’t hesitate and subscribe today. The only regret you will have is that you didn’t find this course sooner!</p>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>Beginners to programming and data science</li>
                        <li>Students eager to learn about job opportunities in the field of data science</li>
                        <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                        <li>SQL Programmers who want to develop business reasoning and apply their knowledge to the solution of various business tasks</li>
                        <li>People interested in a Business Intelligence Analyst career</li>
                    </ul>
                    <h3>Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>James Anderson</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on Ednuv</a>
                                    </div>
                                </div>
                            </div>
                            <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                            <p>His expertise and dedication inspire students to unlock their creative potential and excel in the art of photography</p>
                        </div>
                    </div>
                    <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">James Anderson</span>
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/img/user2.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment">Video Quality!</span>
                            <p>Was really easy to implement and they quickly answer my additional questions!</p>
                        </div>
                        <div class="user-review">
                            <img src="assets/img/user3.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment">Perfect Coding!</span>
                            <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                        </div>
                    </div>
                </div>
                <div class="related-courses">
                    <h3>Related Courses</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            10
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            1 Weeks
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div>
                <div class="courses-sidebar-syllabus">
                    <h3>Course Syllabus</h3>
                    <span class="chapter">Chapter 1</span>
                    <h4>Lessons</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                Introduction
                            </li>
                            <li>
                                <a routerLink="/single-courses">
                                    <span class="number">1.</span> Secret 1: Sell The Problem, Not The Solution (14:37) <span class="free-lesson">Free</span>
                                </a>
                            </li>
                            <li>
                                Quiz - Secret 1: Sell The Problem, Not The Solution
                            </li>
                            <li>
                                <span class="number">2.</span> Secret 2: Think Like a Magazine, Not a Salesman (4:30)
                            </li>
                            <li>
                                Quiz - Secret 2: Think Like a Magazine, Not a Salesman
                            </li>
                            <li>
                                <span class="number">3.</span> Secret 3: Content Is King, but Consistency Is Queen (3:56)
                            </li>
                            <li>
                                Quiz - Secret 3: Content Is King, but Consistency Is Queen
                            </li>
                            <li>
                                <span class="number">4.</span> Secret 4: Punch Above Your Weight Class (11:00)
                            </li>
                            <li>
                                <span class="number">5.</span> Secret 5: Ethically Steal Your Competitors' Customers (8:12)
                            </li>
                            <li>
                                <span class="number">6.</span> Secret 6: Bulid an Audience By Borrowing (10:25)
                            </li>
                            <li>
                                Quiz - Secret 6: Bulid an Audience By Borrowing
                            </li>
                            <li>
                                <span class="number">7.</span> Secret 7: Be a Shadow, Not a Ghost (5:19)
                            </li>
                            <li>
                                <span class="number">8.</span> Bonus: Tips to Be a 5 Stars Freelancer (16:07)
                            </li>
                            <li>
                                <span class="number">9.</span> Closing Thoughts (3:15)
                            </li>
                            <li>
                                Quiz - Secret 7: Be a Shadow, Not a Ghost
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">Chapter 2</span>
                    <h4>Final Quiz</h4>
                    <div class="courses-list">
                        <ul>
                            <li>
                                Final Quiz
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="courses-purchase-info">
                    <h4>Interested in this course for your Business or Team? </h4>
                    
                    <p>Train your employees in the most in-demand topics, with edX for Business.</p>
                    <a routerLink="/single-courses" class="d-inline-block">Purchase now</a>
                    <a routerLink="/single-courses" class="d-inline-block">Request Information</a>
                </div>
            </div>
        </div>
    </div>
</div>