<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Welcome to ecademy</span> -->
            <h2>Success Stories from Our Telone Learning Center</h2>
            <p>Enrolling at Telone Learning Center was a turning point in my academic journey. The engaging courses and dedicated instructors created an environment where I could thrive.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <img src="assets/img/telone/digital-marketing.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a >Tatenda Muchemwa</a></h3>
                        <p>
                            Learning a new language was a dream, and Telone Learning Center made it a reality. The language courses were immersive, and the live sessions with native speakers enhanced my fluency. The cultural exchange programs added an extra layer
                             to my learning experience. Telone truly opened doors to a new world for me!
                        </p>
                    </div>
                </div>
            </div>`     `
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a class="d-block">
                        <img src="assets/img/telone/python-study.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a>Farai Mavhengere</a></h3>
                        <p>Switching careers seemed daunting until I found Telone Learning Center. The flexible schedules and industry-relevant courses allowed me to acquire new skills at my own pace. The supportive instructors and hands-on projects
                             made the transition smooth. Now, I'm thriving in my new career, all thanks to Telone!</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <img src="assets/img/telone/psychometric-tests.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a>Noah Choga</a></h3>
                        <p>
                            As a busy parent, returning to learning seemed impossible. Telone Learning Center's user-friendly platform and diverse course offerings made it feasible. The instructors understood the challenges of adult learners, and the community support was invaluable. Learning has become a fulfilling part of my life, and I'm setting a great example for my children.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>