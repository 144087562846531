<div class="blog-slides">
    <owl-carousel-o [options]="blogSlides">
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                        <img src="assets/img/telone/learning2.jpg" alt="image">
                    <div class="tag">
                        <a>Learning</a>
                    </div>
                </div>
                <!-- <div class="post-content">
                    <ul class="post-meta">
                        <li><a routerLink="/">August 30, 2019</a></li>
                    </ul>
                    <h3><a routerLink="/single-blog" class="d-inline-block">World largest elephant toothpaste experiment in 2019</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                        <img src="assets/img/telone/860X660.jpg" height="10px" alt="image">
                    <div class="tag">
                        <a>Management</a>
                    </div>
                </div>
                <!-- <div class="post-content">
                    <ul class="post-meta">
                        <li><a routerLink="/">August 28, 2019</a></li>
                    </ul>
                    <h3><a routerLink="/single-blog" class="d-inline-block">How to enhance education quality management system</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div> -->
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                        <img src="assets/img/telone/ideas.jpg" alt="image">
                    <div class="tag">
                        <a>Ideas</a>
                    </div>
                </div>
                <!-- <div class="post-content">
                    
                    <h3><a routerLink="/single-blog" class="d-inline-block">Global education: Ideas for the way move forward</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div> -->
            </div>
        </ng-template>
        <!-- <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/content/workforce.jpg" alt="image">
                    </a>
                    <div class="tag">
                        <a routerLink="/">Workforce</a>
                    </div>
                </div>
                <div class="post-content">
                    
                    <h3><a routerLink="/single-blog" class="d-inline-block">New report reimagines the broader education workforce</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template> -->
    </owl-carousel-o>
</div>