<app-header-style-one></app-header-style-one>
 <body>
    <h2>Semester Results</h2>

    <div class="container">
        <h3>BACHELOR OF TECHNOLOGY HONOURS DEGREE IN SOFTWARE ENGINEERING</h3>
        <h3>October 2020 - December 2020</h3>
        <h3>Academic Year: 1 Semester: 1</h3>
        <h3>Decision: Pass</h3>
    </div>
    
    <table>
      <tr>
        <th>Code</th>
        <th>Course</th>
        <th>Grade</th>
        <th>Status</th>
      </tr>
      <tr>
        <td>5CD4087HCT</td>
        <td>Introduction to Computer Science</td>
        <td>A</td>
        <td>Pass</td>
      </tr>
      <tr>
        <td>5CD4087HCT</td>
        <td>Operating Systems</td>
        <td>A</td>
        <td>Pass</td>
      </tr>
      <tr>
        <td>5CD4087HCT</td>
        <td>Fundamentals of Digital Electronics</td>
        <td>A</td>
        <td>Pass</td>
      </tr>
      <tr>
        <td>5CD4087HCT</td>
        <td>Descrete Mathematics</td>
        <td>A</td>
        <td>Pass</td>
      </tr>
      <tr>
        <td>5CD4087HCT</td>
        <td>Software Engineering Fundamentals</td>
        <td>A</td>
        <td>Pass</td>
      </tr>
    </table>
</body>
    
