import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-and-open-access',
  templateUrl: './subscription-and-open-access.component.html',
  styleUrls: ['./subscription-and-open-access.component.scss']
})
export class SubscriptionAndOpenAccessComponent {

}
