import { Component } from '@angular/core';

@Component({
  selector: 'app-classes-timetable',
  templateUrl: './classes-timetable.component.html',
  styleUrls: ['./classes-timetable.component.scss']
})
export class ClassesTimetableComponent {

}
