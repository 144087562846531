import { Component } from '@angular/core';

@Component({
  selector: 'app-online-telone-training',
  templateUrl: './online-telone-training.component.html',
  styleUrls: ['./online-telone-training.component.scss']
})
export class OnlineTeloneTrainingComponent {

}
