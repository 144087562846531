<div class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Discover Mission</span>
                <h2>Why our college is better</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>Quality can be better than quantity in this platform</h3>
                        <p>We're committed to providing top-notch support for national and international students, prioritizing meaningful engagement over sheer volume. Whether you're seeking academic guidance or vibrant peer connections, our platform offers a curated space where every interaction matters.</p>
                        <p>At our forum, quality is our cornerstone, ensuring that every interaction leaves a lasting impact on your academic and personal journey. Join our community today and experience the difference firsthand!</p>
                        <p>
                            At Telone Center for Learning, we champion the idea that quality holds more value than quantity in the realm of education. In a world saturated with information, we prioritize delivering content that is not just abundant but, more importantly, insightful and impactful.
                        </p>
                        <p>
                            Our focus on quality ensures that every piece of content on our platform is meticulously curated, undergoing a stringent evaluation process to guarantee accuracy, clarity, and educational merit.
                        </p>
                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>A place where you can achieve</h3>
                        <p>A place where your aspirations converge with opportunities for achievement. Here, amidst a vibrant community of learners and mentors, you'll discover pathways to success that align with your goals and aspirations.</p>
                        <p>Within our inclusive environment, you'll find a wealth of resources, from insightful discussions to collaborative projects, all geared towards helping you unlock your full potential.</p>
                        <p>
                            At Telone, we envision our platform as more than just a space for learning; it is a dynamic environment where achievement is not just encouraged but expected. We understand that the pursuit of knowledge is not merely about gathering facts but about the transformation of individuals into accomplished learners.
                        </p>
                        <p>
                            Our commitment to achievement is reflected in our approach to learning, which emphasizes practical application, critical thinking, and real-world skills. Telone is more than a repository of information; it is a place where learners actively engage with the material, enabling them to not only acquire knowledge but also apply it meaningfully.
                        </p>
                        <!-- <a routerLink="/" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>