import { Component } from '@angular/core';

@Component({
  selector: 'app-exam-timetable',
  templateUrl: './exam-timetable.component.html',
  styleUrls: ['./exam-timetable.component.scss']
})
export class ExamTimetableComponent {

}
