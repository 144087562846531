<app-header-style-one></app-header-style-one>
<div class="container">
    <header>
      <h1 style="color: red;">Telone Centre for Learning Library</h1>
      <p>Welcome to Telone Centre for learning online library. The library has a mandate to support innovative research, teaching and learning through provision of current information resources. We are here to serve 24/7.</p>
    </header>
    <section class="vision-mission">
      <h2 style="color: red;">Vision</h2>
      <p>To create a remarkable online and offline presence to ensure that the library is acknowledged as the primary source of research and academic information.</p>
      <h2 style="color: red;">Mission</h2>
      <p>To be among the top and join forces with the TCFL in order to guarantee that students fulfil the requirements of the TCFL. The TCFL curriculum and research objectives are supported by the Library's creation, preservation, organization, and accessibility of a broad range of pertinent physical and digital collections.</p>
    </section>
    <section class="operational-times">
      <h2>Library Operational Times</h2>
      <h3>During Semester:</h3>
      <ul>
        <li>Monday – Friday: 0830 hours – 16.15 hours</li>
        <li>Saturday: 0900 hours – 1300 hours</li>
        <li>Public Holidays: CLOSED</li>
      </ul>
      <h3>During Vacation:</h3>
      <ul>
        <li>Monday – Friday: 0900 hours – 1615 hours</li>
        <li>Sundays & Public Holidays: CLOSED</li>
      </ul>
    </section>
  </div>
