<section class="mission-area ptb-100">
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                <!-- <span class="sub-title">Discover Learning</span> -->
                <!-- <h2>Why TCFL</h2> -->
                <span class="sub-title">At a glance</span>
                 <h2><a routerLink="/events">New and Events</a></h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <!-- <h3>Quality can be better than quantity</h3>
                        <p> We prioritize meaningful contributions for an enhanced user experience. Encouraging valuable, insightful, and accurate information over volume fosters a community of thoughtful discussions and shared expertise. Our emphasis on quality creates a space where users can engage, learn, and collaborate effectively.</p>
                        <p> Quality contributions build individual credibility and enrich the platform's knowledge. We appreciate users who offer well-reasoned responses, share expertise, and uphold a high content standard. Together, we prioritize quality interactions over quantity, creating an environment of excellence. Thank you for being part of this commitment! </p> -->
                        <h3><a>TCFL February Intake</a></h3>
                        <p>TelOne Centre for Learning February 2025 Intake Now Open!
                            Discover a world of knowledge and unlock your potential with our diverse range of courses. For more information, email us <a href="mailto:studentaffairs@telone.co.zw" style="color: #0000EE; font-weight: bolder; font-style: italic;" >here.</a></p>
                        <p></p>
                        <p></p><p>
                    </ng-template>
                    <ng-template carouselSlide>
                        <!-- <h3>A place where you can achieve</h3>
                        <p>Discover a space where learning transcends boundaries! Our center is a haven for those seeking knowledge and growth. Achieve your academic and personal goals with expert guidance and innovative resources.</p>
                        <p> Join a community dedicated to fostering a love for learning, where every individual journey is valued. Welcome to a place where education becomes a transformative experience!</p> -->
                        <h3><a>Telone Hosts Career Guidance</a></h3>
                        <p>Telone hosted high school students from Zvishavane in August for a carrier guidance session. The event featured lecturers from TCFL who provided the students with a look into the ICT field and also a chance to learn from industry professionals.</p>
                        
                    </ng-template>
                    <ng-template carouselSlide>
                        <!-- <h3>A place where you can achieve</h3>
                        <p>Discover a space where learning transcends boundaries! Our center is a haven for those seeking knowledge and growth. Achieve your academic and personal goals with expert guidance and innovative resources.</p>
                        <p> Join a community dedicated to fostering a love for learning, where every individual journey is valued. Welcome to a place where education becomes a transformative experience!</p> -->
                        <h3><a >Huawei seeds for the future</a></h3>
                        <p>TCFL students shines at Huawei Seeds for the Future
                            We are pleased to announce that 7 of our students were selected to participate at the Huawei Seeds for the Future. At platform that allows young innovators to pitch ideas which are then incubated by Huawei. Keep Shining TCFL!</p>

                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>