<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Simbarashe N Taruvinga </span>
                                <h1>President</h1>
                                <p> A paragon of intellectual dexterity. A dedicated and strong leader</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Tavongaishe Kamanga</span>
                                <h1>Secretary General</h1>
                                <p>  Motive of fostering a holistic collegiate experience by providing innovative and intentional co-curricular engagement opportunities that transform students and support their sense of belonging through inclusive involvement and leadership in diverse organizations and programs.</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Tafadzwa George Gwazvo</span>
                                <h1>Minister of Sports </h1>
                                <p> With a profound passion for sports and a keen understanding of its transformative potential,i'm dedicated to revolutionizing the sports landscape for students and athletes alike.My journey, rooted in rugby where Vox_Da_Grace started, excellence with Pitbulls Sports Club at Churchill, provides invaluable insights into the challenges and opportunities within the sporting realm</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Charlotte Mathe</span>
                                <h1>Minister of Gender</h1>
                                <p> As Minister of Gender, I passionately advocate for equality and inclusion, ensuring all individuals are treated with dignity and respect, irrespective of gender identity or expression. My initiatives focus on creating safe spaces, providing education on gender identity, and fostering a more just society for all</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Paul Makarimayi</span>
                                <h1>Treasurer</h1>
                                <p>Dedicated Data Science student and patriot committed to ethical leadership. Engaging with peers, fostering trust, and promoting open communication. Passionate about development and stress relief through sports and clubs. Committed to serving the best interests of the community</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">McDonald Chidakwa</span>
                                <h1>Minister of Innovation and Technopreneurs</h1>
                                <p>McDonald, a dedicated student pursuing a diploma in Digital Marketing at Telone Center for Learning, is not only a passionate advocate for human rights with Amnesty International but also a Gender Champion with Padaremen. His commitment to serving the youth and students aligns seamlessly with the vision of a Minister of Innovation and Entrepreneurship, poised to drive forward-thinking initiatives and empower aspiring entrepreneurs in the digital landscape</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Othaniel Mulema</span>
                                <h1>Post-Minister of entertainment</h1>
                                <p>Experience the pinnacle of entertainment with our vibrant school community! Under the visionary leadership of our esteemed Post-Minister of Entertainment, we bring you a dynamic array of events and activities designed to ignite your passions and cultivate unforgettable memories. From electrifying performances to engaging workshops, join us on a journey where creativity knows no bounds. Stay tuned for upcoming events and let the magic unfold</p>
                                <div class="btn-box">
                                    <!-- <a href="https://erp.edmatix.com/login" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Admin Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a> -->
                                    <a href=" https://kyczw.edmatix.com" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Student Portal</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>