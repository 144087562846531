import { Component } from '@angular/core';

@Component({
  selector: 'app-entrance-registration',
  templateUrl: './entrance-registration.component.html',
  styleUrls: ['./entrance-registration.component.scss']
})
export class EntranceRegistrationComponent {

}
