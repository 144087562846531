<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg11">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Diploma</li>
            </ul>
            <h2>Diploma</h2>
        </div>
    </div>
</div>


<div class="courses-area ptb-100" style="background-image: url('/assets/img/telone/partners/maths.jpg'); background-size: cover; background-position: center;">
  
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30" style="border-radius: 15px;">
                    <div style="align-items: center;" class="courses-image">
                        <a class="d-block"> <i style="font-size: 120px;" class='bx bx-code-alt'></i></a>
                        <!-- <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div> -->
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>HEXCO</span>
                        </div>
                        <h3><a  class="d-inline-block">Telecommunications Engineering</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $500
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>HEXCO</span>
                        </div>
                        <h3><a  class="d-inline-block">Software Engineering</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>HEXCO</span>
                        </div>
                        <h3><a  class="d-inline-block">Data Science </a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>HEXCO</span>
                        </div>
                        <h3><a  class="d-inline-block">Computer Networking </a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>HEXCO</span>
                        </div>
                        <h3><a  class="d-inline-block">Cyber Security</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <h3><a  class="d-inline-block">Digital Marketing</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <h3><a  class="d-inline-block">Financial Engineering</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <h3><a  class="d-inline-block">Digital Marketing</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <h3><a  class="d-inline-block">Digital Marketing</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <h3><a  class="d-inline-block">Business Analytics</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 years
                            </li>
                            <li class="courses-price">
                                $450
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <span style="font-style: italic;">Postgraduate Diploma</span>
                        <h3><a  class="d-inline-block">Digital Marketing</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 1 year
                            </li>
                            <li class="courses-price">
                                $600
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <span style="font-style: italic;">Postgraduate Diploma</span>
                        <h3><a  class="d-inline-block">Business Analytics</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 1 years
                            </li>
                            <li class="courses-price">
                                $600
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-box without-box-shadow mb-30">
                    <!-- <div class="courses-image">
                        
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-3" class="d-block">TECHNICAL DEPARTMENT</a>
                        </div>
                    </div> -->
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <span>BUSINESS DEPARTMENT</span>
                        </div>
                        <span style="font-style: italic;">Postgraduate Diploma</span>
                        <h3><a  class="d-inline-block"> Digital Financial Engineering</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 1 year
                            </li>
                            <li class="courses-price">
                                $600
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          
        
        </div>
    </div>
</div>