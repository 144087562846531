<div class="feedback-form-inner">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <!-- <div class="feedback-form-image">
                <img src="assets/img/content/content-image.jpg" alt="image">
            </div> -->
        </div>
        <div class="col-lg-7 col-md-12">
            <!-- <div class="feedback-form">
                <h3>Here is Students Feedback Form</h3>
                <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your name*">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Your email*">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <textarea cols="30" rows="4" class="form-control" placeholder="Message here"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <button type="submit" class="default-btn"><i class='bx bx-send icon-arrow before'></i><span class="label">Submit</span><i class="bx bx-send icon-arrow after"></i></button>
                        </div>
                    </div>
                </form>
            </div> -->
        </div>
    </div>
</div>