<section class="about-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 for national and international students.</h6>
                    <p>
                        Join our vibrant community of national and international students where learning knows no borders. Engage in discussions, seek advice, and foster connections around the clock. Your academic journey just got a whole lot more connected.
                    </p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p>
                    </div>
                    <!-- <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/content/content-image-3.jpg" alt="image">
                    <img src="assets/img/content/learn.jpg" alt="image">
                    <!-- <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-4"></div>
</section>