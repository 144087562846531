<div class="partner-slides" style="background-color: white;">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/all-partners.jpeg" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/CSZ.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/Fanira.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/Huawei.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/ICDL.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/MUAST.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/NUST.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/SATA.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/telone/partners/Zimbabwe-engineers.png" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>