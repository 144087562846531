<app-header-style-one></app-header-style-one>
<div class="container">
    <h1>List of electronic Journals</h1>
    
    <h3>Academic Journals</h3>
    <p>
        Academic Journals currently publishes 108 peer-reviewed open access journals covering art
         and humanities, engineering, medical science, social sciences, biological sciences, physical 
         sciences and agricultural sciences. <a href="https://academicjournals.org/">Read more</a>
    </p>

    <h3>ACBF The African Capacity Building Foundation</h3>
    <p>
        Welcome to ACBF's Knowledge Resource Corner (KRC), a virtual library on capacity development. 
        The Knowledge Resource Corner is a one stop shop digital library that offers fast, well-structured 
        and unified interface to access free full-text copies on capacity development produced and published by ACBF, 
        its networks and partners. KRC's collections comprise books, occasional papers, working papers, reports, development memoirs,
         lesson notes, conference papers etc. Other online reference sources accessible via the Knowledge Resource Corner include several
          databases, gateways, portals, e-journals, e-books and e-news. <a href="http://elibrary.acbfpact.org/">Read more</a>
    </p>

    <h3>AJOL</h3>
    <p>
        African Journals OnLine (AJOL) is the world's largest and preeminent platform of African-published scholarly journals. 
        AJOL is a Non-Profit Organisation that (since 1998) works to increase global & continental online access, 
        awareness, quality & use of African-published, peer-reviewed research. <a href="https://www.ajol.info/index.php/ajol">Read more</a>
    </p>

    <h3>American Institute of Mathematics Open Textbook Initiative</h3>
    <p>
        The American Institute of Mathematics (AIM) maintains a list of open source and open access
         mathematics textbooks that have met the AIM Editorial Board’s evaluation criteria and are 
         suitable for use in traditional university courses. <a href="http://www.aimath.org/textbooks/approved-textbooks/">Read more</a>
    </p>

    <h3>American Institute of Physics</h3>
    <p>
        American Institute of Physics publishes journals, magazines, and a conference proceedings series.
         AIP's Scitation platform hosts over 2 million articles from more than 200 scholarly publications 
         for 28 earned society publishers. <a href="https://publishing.aip.org/publishing">Read more</a>
    </p>
    <h3>American Physical Society</h3>
    <p>
        The American Physical Society is one of nine publishers participating in a CrossRef pilot project
         that uses Google to provide full-text searching of scholarly research. This database contains physical science documents only.
         <a href="http://journals.aps.org/search">Read more</a>
    </p>

    <h3>Annual Reviews</h3>
    <p>The database covers Biochemistry, Microbiology, Physical Sciences and social sciences.
        <a href="http://www.annualreviews.org/">Read more</a>
    </p>

    <h3>AU Press</h3>
    <p>
        Athabasca University Press (AUP) is committed to making research and scholarship freely accessible through the publication 
        of books and journals in open access. Our unique publishing model offers authors the opportunity to reach a wide readership 
        and creates an innovative forum for the exchange of ideas. <a href="http://www.aupress.ca/">Read more</a>
    </p>

    <h3>BIOONE</h3>
    <p>BioOne Complete is BioOne's full-text database of more than 200 leading journals in the world. <a href="http://www.bioone.org/">Read more</a></p>

    <h3>BCcampus Open Textbooks Collection</h3>
    <p>The BC Open Textbook Project was first announced in October 2012 by the British Columbia Minister of Advanced Education.
         BCcampus has created a collection of open textbooks targeting high-enrollment subject areas, such as the arts, 
         sciences, social sciences, and business studies, as well as trades and skills training. In total, the BC Open
          Textbook Collection contains over 70 openly licensed textbooks. <a href="http://www.open.bccampus.ca/find-open-textbooks/">Read more</a>
    </p>

    <h3>BioMed Central</h3>
    <p>
        Pioneer of open access publishing, BMC has an evolving portfolio of high quality peer-reviewed journals including broad interest titles such as BMC Biology and BMC Medicine, specialist journals such as Malaria Journal and Microbiome, and the BMC Series. Expanding beyond biomedicine into the physical sciences, mathematics and engineering disciplines, BMC now offers a wider portfolio of subject fields on a single open access platform. At BMC, research is always in progress. Biomedicine, Chemistry, Dentistry, Education,Engineering, Environment, Life Sciences, Material Science, Mathematics, Medicine & Public Health, Philosophy, Physics Psychology. 
        <a href="https://www.biomedcentral.com/">Read more</a></p>

    <h3>Bookboon</h3>
    <p>
        Bookbon is a free to use website that lets you download eBooks. The eBooks are categorized either as textbooks or books for business or travel. Accounting, Career & Study advice, Economics & Finance, Engineering, IT & Programming, Languages, Marketing & Law, Natural Sciences, Statistics & Mathematics, Strategy & Management, Business, Accounting & Finance, Career management, Communication & Presentation, Engineering, Entrepreneurship, IT Management, Job search & CV, Management & Strategy, Marketing & Sales, Office Programs & Software, Personal Development.  
        <a href="http://bookboon.com/">Read more</a></p>

    <h3>Cambridge University Press</h3>
    <p>
        Cambridge University Press currently publishes over 300 peer-reviewed academic journals for the global market. Containing the latest research from a broad sweep of subject areas.
        <a href="http://journals.cambridge.org/action/login">Read more</a></p>

    <h3>Canadian Science Publishing (publisher of the NRC Research Press journals)</h3>
    <p>
        Canadian Science Publishing provides online peer-reviewed journals in a broad range of scientific discipline.
        <a href="http://www.nrcresearchpress.com/">Read more</a></p>

    <h3>Codesria</h3>
    <p>
        Textbooks Series Book Series Africa in the New Millennium Series Gender Series African Future Series Interventions Series State of the Art Series & New Path Series. 
        <a href="http://www.codesria.org/">Read more</a></p>

    <h3>Cogprints</h3>
    <p>Artificial intelligence, robotics, vison, learning, speech, neural networks, Philosophy (e.g., mind, language, knowledge, science, logic), Biology 
        (e.g., ethology, behavioral ecology, sociobiology, behaviour genetics, evolutionary theory), Medicine (e.g., Psychiatry, Neurology, human genetics, Imaging), Anthropology (e.g., primatology, cognitive ethnology, archeology, paleontology), as well as any other portions of the physical, social and mathematical sciences. 
        <a href="http://www.cogprints.org/">Read more</a></p>

    <h3>College Open Textbooks</h3>
    <p>
        The College Open Textbooks is a collaborative collection of peer reviewed open textbooks.
        <a href="http://www.collegeopentextbooks.org/">Read more</a></p>

    <h3>DISA: Digital Innovation South Africa</h3>
    <p>
        Affidavits, Articles, Biographies, Books, Briefs (Legal Documents), Circulars, Conference Papers, Constitution, Correspondence, Essays, Interviews, Journal article, Journal Journals, Legislation, Letters, Maps, Memorandums, Music, Newsletters, Oral Histories, Painting, Pamphlets, Photograph, Poetry, Policy documents, Posters, Press articles, Presss, Transcripts, Trial Proceedings.
        <a href="http://www.disa.ukzn.ac.za/">Read more</a></p>

    <h3>DOAB: Directory of Open Access Books</h3>
    <p>
        Directory of Open Access Books provides freely accessible books and therefore free to read. All books listed in DOAB are free to read and share for non-commercial use.Agriculture and Food Sciences, Arts and Architecture, Biology and Life Sciences, Business and Economics, Chemistry, Earth and Environmental Sciences, General Works, Health, Sciences, History and Archaeology, Languages and Literatures, Law and Political, Science, Mathematics and Statistics, Philosophy and Religion, Physics and Astronomy, Science General Social Sciences, Technology and Engineering.
        <a href="http://www.doabooks.org/">Read more</a></p>

    <h3> DOAJ – Directory of Open Access Journals</h3>
    <p>
        DOAJ aims to increase the visibility and ease of use of open access scientific and scholarly journals. Currently 719 journals are searchable at article level. “Agriculture, Auxiliary sciences of history, Bibliography Library science Information resources, Education Fine Arts, General Works, Geography, Anthropology, Recreation, History (General) and history of Europe, History America, Language and Literature, Law, Medicine, Military, Science, Music and books on Music, Naval Science, Philosophy, Psychology, Religion, Political science, Science, Social Sciences, Technology”.
        <a href="https://doaj.org/">Read more</a></p>

    <h3>E-Book Central</h3>
    <p>Ebrary offers authoritative e-books in a wide range of subject areas: Business & Economics, Computers & IT, Education, Engineering & Technology, History & Political Science, Humanities, Interdisciplinary & Area Studies, Language, Literature & Linguistics, Law, International Relations & Public Policy, Life Sciences, Medical, Nursing & Allied Health, Physical Sciences, Psychology & Social Work, Religion, Philosophy & Classics, and Sociology & Anthropology.
        <a href="http://site.ebrary.com/lib/lsuaczw/home.action">Read more</a></p>

    <h3>EBSCO Open Dissertations</h3>
    <p>Created from a collaboration between EBSCO and BiblioLabs, _EBSCO Open Dissertations™_ is a new, free electronic theses and dissertations database offering access to more than 800,000 ETDs. It is available to all and searchable.
        <a href="http://www.opendissertations.org/">Read more</a> </p>

   <h3> Edtech Books</h3>
   <p>Contains Free Textbooks and Journals by Industry-Leading Professors and Other Experts  in Education Technology.
    <a href="http://edtechbooks.org/">Read more</a></p>

   <h3>E-LIS. E-prints in Library and Information Science</h3>
   <p>Multi Linguistic Multi Diversified Journals Ranging from Social Research to medicinal categories.
    <a href="http://www.eprints.rclis.org/">Read more</a></p>

   <h3>ELDIS</h3>
   <p>Eldis is hosted by the Institute of Development Studies based in the UK but our services profile work by a growing global network of research organisations and knowledge brokers.
    <a href="https://www.eldis.org/">Read more</a></p>

   <h3>Emerald insight</h3>
   <p>Emerald is a leading English language publisher of academic and professional literature covering wide range of subjects and full text articles.
    <a href="http://www.emeraldinsight.com/">Read more</a></p>

   <h3>Green Tea Press</h3>
   <p>
    Green Tea Press offers free textbooks developed by Allen Downey, a Professor of Computer Science at Olin College in Needham, MA. His textbooks, such as Think Python, Think Bayes, and Think Stats are generally related to computer science and data science. All of the textbooks are available under free licenses that allow readers to copy and distribute the texts, as well as modify them in order to adapt the books to different needs.
    <a href="http://www.greenteapress.com/wp/">Read more</a></p>

   <h3>HighWire Press Free Online Full-text Articles</h3>
   <p>
    HighWire is a trusted global provider of innovative scholarly publishing technology. In 1995, spearheaded the digital publishing revolution, working with Google to digitize and index academic journals.
    <a href="http://home.highwire.org/">Read more</a></p>

   <h3>Google Books</h3>
   <p>
    Google Books provides a simple way to broadly search for scholarly literature. From one place, you can search across many discipline, from academic publishers, professional societies, online repositories, universities and other web sites. Google Books helps you find relevant work across the world of scholarly research.
    <a href="https://books.google.com/books">Read more</a></p>

   <h3>Google scholar</h3>
   <p>
    Google Scholar provides a simple way to broadly search for scholarly literature. From one place, you can search across many disciplines and sources: articles, theses, books, abstracts and court opinions, from academic publishers, professional societies, online repositories, universities and other web sites. Google Scholar helps you find relevant work across the world of scholarly research.
    <a href="https://scholar.google.com/">Read more</a></p>

   <h3>Hindawi</h3>
   <p>
    Hindawi is one of the world’s largest publishers of peer-reviewed, fully Open Access journals. Built on an ethos of openness, we are passionate about working with the global academic community to promote open scholarly research to the world. With the help of our academic Editors, based in institutions around the globe, we are able to focus on serving our authors while preserving robust publishing standards and editorial integrity.
    <a href="https://www.hindawi.com/">Read more</a></p>

   <h3>INTERNATIONAL LABOUR ORGANIZATION (ILO)</h3>
   <p>
    ILO is a specialized agency of the United Nations. It covers a number of subjects in social sciences such as Child Labour, Collective bargaining and labour relations, Disability and work Domestic workers, Economic and social development, Globalization , Multinational enterprises ,Small and Medium Enterprises , Sustainable enterprises, Work, peace and resilience, Employment security, Equality and discrimination, Gender equality, Forced labour, human trafficking and slavery, Labour law , Labour administration, Labour inspection and administration, Labour migration, Millennium Development Goals, Poverty, Rural economy, Social protection and Youth employment.
    <a href="https://www.ilo.org/">Read more</a></p>

   <h3>IMF eLibray</h3>
   <p>
    IMF eLibrary provides comprehensive data and original analysis, with coverage of Monetary policy, Financial stability, Economic development, Forecasts and outlooks, Poverty reduction.
    <a href="http://www.elibrary.imf.org/">Read more</a></p>

   <h3>Institute for Operations Research and the Management Sciences (INFORMS) INFORMS</h3>
   <p>
    publishes scholarly journals on Management Science and Operations Research, a journal for the practice of OR/MS (Interfaces), the new online-only journal Service Science, as well as an open access journals.
    <a href="http://pubsonline.informs.org/">Read more</a></p>

   <h3>InTech Open Access Books</h3>
   <p>
    InTech is the publisher of one of the largest open access collection of books in the world, featuring contributors from 3,143 research institutions and 152 countries. With more than 2,500 open access books published and over 82 million downloads to date, InTech makes research visible and available to international scholarly communities in the fields of science, humanities, technology and medicine.
    <a href="http://www.intechopen.com/books">Read more</a></p>

   <h3>OAPEN Library</h3>
   <p>
    The OAPEN Foundation is a non-profit foundation dedicated to open access publishing of academic books. OAPEN provides a platform for the full text dissemination of open access books and provides services to publishers and libraries. OAPEN currently offers a quality controlled collection of freely accessible academic books, mainly in the area of humanities and social sciences.
    <a href="http://www.oapen.org/">Read more</a></p>

   <h3>Open Book Publishers</h3>
   <p>
    United Kingdom based freely accessible academic book publisher in humanities, social science, mathematics and science.
    <a href="https://www.openbookpublishers.com/books">Read more</a></p>

   <h3>Open Science Directory</h3>
   <p>Journals for all things Science. <a href="http://www.opensciencedirectory.net/">Read more</a></p>

   <h3>OpenStax</h3>
   <p>OpenStax is part of Rice University which is a non profit charitable corporation whose mission is to improve educational access and learning for everyone. It publishes openly lisences books with subject ranges including Maths(Statistics), Social Science (Principles of Economics, Principles of Macro-economics, Principles of Micro-economics), Humanities (History) etc. These textbooks are available in various e-text formats, including interactive iBook, PDF, web view, EPUB and Bookshare.
    <a href="http://www.openstax.org/">Read more</a>
   </p>
    
   <h3>Open SUNY Textbooks</h3>
   <p>
    Open SUNY Textbooks is an open access textbook publishing initiative established by State University of New York libraries and supported by SUNY Innovative Instruction Technology Grants. Participating libraries in the 2012-2013 pilot included SUNY Geneseo, College at Brockport, College of Environmental Science and Forestry, SUNY Fredonia, Upstate Medical University, and University at Buffalo, with support from other SUNY libraries and SUNY Press. Open SUNY Textbooks currently offers 22 textbooks on topics such as nursing, sciences, mathematics, and literature.
    <a href="http://www.textbooks.opensuny.org/">Read more</a></p>

   <h3>Open Text Book Library</h3>
   <p>
    Open textbooks are licensed by authors and publishers to be freely used and adapted. Download, edit and distribute them at no cost. Subjects include Accounting & Finance, Business, Management & Marketing, Computer Science & Information Systems, Economics, Foreign Languages, General Education, Humanities, Mathematics & Statistics, Natural & Physical Sciences, Social Sciences.
    <a href="http://open.umn.edu/opentextbooks">Read more</a></p>

   <h3>Orange Grove Texts Plus</h3>
   <p>
    Orange Grove Texts Plus (OGT+) is a joint initiative of the University Press of Florida and The Orange Grove, Florida’s Digital Repository. The goal of this partnership is to reduce the cost of books to students by offering texts that are affordable, accessible, and adaptable to reader preferences. The OGT+ collection features over 100 textbooks covering a wide range of disciplines available for instant PDF download.
    <a href="http://www.orangegrovetexts.org/">Read more</a></p>

   <h3>Mendeley</h3>
   <p>
    Mendeley is a free reference manager that can help you store, organize, note, share and cite references and research data: <br>• Automatically generate bibliographies<br> • Collaborate easily with other researchers online<br> • Easily import papers from other research software<br> • Find relevant papers based on what you're reading<br> • Access your papers from anywhere online.
    <a href="http://mendeley.com/">Read more</a></p>

   <h3>Oxford Journals Collection</h3>
   <p>
    Publishes over 300 journals in the humanities, social sciences, law, science, and medicine, two-thirds of which are published in partnership with learned and professional societies.
    <a href="http://www.oxfordjournals.org/en/">Read more</a> </p>

   <h3>Project Muse</h3>
   <p>
    Project Muse provides Peer-reviewed journals and books from non-profit scholarly publishers, with wide variety of subjects in humanities and social science subjects.
    <a href="http://muse.jhu.edu/">Read more</a></p>

   <h3> Research4Life</h3>
   <p>
    Research4Life provides institutions in low-and middle-income countries with online access to academic and professional peer-reviewed content. We aim to improve teaching, research and policymaking in health, agriculture, the environment and other life, physical and social sciences.
    <a href="https://portal.research4life.org/">Read more</a></p>

   <h3> Sabinet Open Access Journal Collection</h3>
   <p>
    Business and Finance, Education, Labour, Law, Medicine & Health, Religion, Science, Technology and Agriculture, Social Sciences and Humanities, Open Access, African Journal.
    <a href="http://www.reference.sabinet.co.za/">Read more</a></p>

   <h3>Science Publications</h3>
   <p>
    Science Publications offers peer-reviewed, open access academic journals. They aim to provide researchers, writers, academic professors and students with up-to-date research in a broad range of areas.
    <a href="https://www.sciencedirect.com/">Read more</a></p>

   <h3>Science Open</h3>
   <p>
    ScienceOpen provides researchers with a wide range of tools to support their research – all for free.Resources range include Engineering, Psychology, Education, Nutrition and Dietetics, History.
    <a href="http://www.scienceopen.com/">Read more</a></p>

   <h3>Springer Open</h3>
   <p>
    The SpringerOpen portfolio has grown tremendously since its launch in 2010, so that we now offer researchers from all areas of science, technology, medicine, the humanities and social sciences a place to publish open access in journals and books. Publishing with SpringerOpen makes your work freely available online for everyone, immediately upon publication, and our high-level peer-review and production processes guarantee the quality and reliability of the work.
    <a href="https://www.springeropen.com/">Read more</a></p>

   <h3>The Free Library</h3>
   <p>
    The Free Library has offered free, full-text versions of classic literary works from hundreds of celebrated authors, whose biographies, images, and famous quotations can also be found on the site. Recently, The Free Library has been expanded to include a massive collection of periodicals from hundreds of leading publications covering Business and Industry, Communications, Entertainment, Health, Humanities, Law, Government, Politics, Recreation and Leisure, Science and Technology, and Social Sciences. This collection includes millions of articles dating back to 1984 as well as newly-published articles that are added to the site daily.
    <br> The Free Library is an invaluable research tool and the fastest, easiest way to locate useful information on virtually any topic. Explore the site through a keyword search, or simply browse the enormous collection of literary classics and up-to-date periodicals to find exactly what you need.    
    <a href="http://www.thefreelibrary.com/">Read more</a></p>

   <h3>UCL Press</h3>
   <p>
    Based at UCL, one of the world’s leading research universities, our primary outputs are scholarly monographs and edited collections, but we also publish textbooks, journals and other innovative research outputs. Our books have been accessed more than 5 million times by readers across the world.
   <br>   UCL Press makes all its books and journals available in open access form to download freely in PDF form or read online anywhere in the world. They can be downloaded freely from UCL Press’s website or from the many other platforms where they are distributed. UCL believes in the principles of open access as the best way to solve the world’s global challenges.
   <a href="http://uclpress.co.uk/">Read more</a></p>

   <h3>UNESDOC Digital Library</h3>
   <p>
    The UNESCO Digital Library is a key tool for enabling UNESCO’s mission of building peace in the minds of people, in particular by “advancing the mutual knowledge and understanding, and encouraging cooperation among the nations in all branches of intellectual activity, including […] the exchange of publications, objects of artistic and scientific interest and other materials of information”.
    <a href="http://unesdoc.unesco.org/">Read more</a></p>

   <h3>Unpaywall</h3>
   <p>
    Unpaywall is run by OurResearch, a nonprofit dedicated to making scholarship more accessible to everyone . Open is our passion. So its only natural our source code is open, too. Freely accessible journals integrated from thousands of library systems and search platforms. 
    <a href="http://unpaywall.org/">Read more</a></p>

   <h3>World Bank</h3>
   <p>
    Documents in both text and downloadable pdf format can be accessed by browsing or searching. Most documents are also available in depository libraries around the world. You can use the options under the search box to browse through the documents by country, type, or sector, or search via the Advanced Search page. The types of documents you will find include: Board Documents, Country Focus Documents, Economic & Sector Work and Project Documents.
    <a href="http://documents.worldbank.org/curated/en/home">Read more</a></p>

   <h3>World Digital Library</h3>
   <p>
    The WDL has stated that its mission is to promote international and intercultural understanding, expand the volume and variety of cultural content on the Internet, provide resources for educators, scholars, and general audiences, and to build capacity in partner institutions to narrow the digital divide within and among countries.
    <a href="https://www.wdl.org/en/search/?q=&qla=en">Read more</a></p>

   <h3>Wiley Open Access</h3>
   <p>
    Wiley has a program of fully open access journals and most of Wiley’s subscription-based journals offer an open access option called hybrid open access.Our open access accounts enable research institutions, funders, and corporations to financially support their authors to publish open access. We offer open access accounts that are flexible, help institutions with their budgetary needs, and make account management easy. Transformational agreements allow researchers unlimited read access to a portfolio of journals, plus funding to cover Article Publication Charges when choosing to publish open access.
    <a href="http://www.wileyopenaccess.com/">Read more</a></p>

   <h3>Zotero</h3>
   <p>
    Zotero is a free, open-source research tool that helps you collect, organize, and analyze research and share it in a variety of ways. Zotero includes the best parts of older reference manager software — the ability to store author, title, and publication fields and to export that information as formatted references.
    <a href="https://www.zotero.org/">Read more</a></p>
</div>



<p>add your content here</p>
