<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <!-- <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Call: 
                            <a href="tel:+502464674">+785 302 141</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
    <div class="navbar-area navbar-style-three" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="/assets/img/tcfl.jpg" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                                <li class="nav-item"><a href="https://erp.edmatix.com/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Staff Portal</a></li>
                                <li class="nav-item"><a href="https://elearningtcfl.edmatix.com" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Student Portal</a></li>
                                <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News and  Events</a></li>
                                <!-- <li class="nav-item"><a routerLink="/instructor-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructors</a></li> -->
                                <li class="nav-item"><a routerLink="/shop-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Campus </a></li>
                                <!-- <li class="nav-item"><a routerLink="/distant-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Distant Learning</a></li> -->
                                <!-- <li class="nav-item"><a href="#" class="nav-link">Library <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/modern-teaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Modern Teaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-schooling" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Schooling</a></li>
                                        <li class="nav-item"><a routerLink="/learning-school-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Learning School Online</a></li>
                                        <li class="nav-item"><a routerLink="/remote-training-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Remote Training Online</a></li>
                                    </ul>
                                </li> -->
                                <!-- <li class="nav-item"><a href="#" class="nav-link">Admissions <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/business-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/health-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Health Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/language-school" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Language School</a></li>
                                        <li class="nav-item"><a routerLink="/gym-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gym Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-motivation-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Motivation Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-education-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-training-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Training Course</a></li>
                                    </ul>
                                </li> -->

                                
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Admissions <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/entrance-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Entrance application</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Registration <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/training-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Training</a></li>
                                        <li class="nav-item"><a routerLink="/deploma-registation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Diploma/Degree</a></li>
                                       <!-- <li class="nav-item"><a routerLink="/degree-registration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Degree</a></li> -->
                            
                                    </ul>  
                                </li>
                                <li class="nav-item"><a routerLink="/tcfl-calendar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">TCFL Calendar</a></li>
                                <li class="nav-item"><a routerLink="/administration-circular" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Administration Circular</a></li>
                                <li class="nav-item"><a routerLink="/exam-timetable" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Application Forms</a></li>
                                <!-- <li class="nav-item"><a href="#" class="nav-link">Application Forms <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                       
                                        <li class="nav-item"><a routerLink="/classes-timetable" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Diploma</a></li>
                                        <li class="nav-item"><a routerLink="/semester-timetable" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">POST GRAD</a></li>
                                    </ul>  
                                </li> -->
                              
                               
                                  </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Library <i class='bx bx-chevron-down'></i></a>
                           
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="#" class="nav-link">E-Resources <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/subscription-and-open-access" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Subscription and Open Access Database</a></li>
                                    </ul>  
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Library Services <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/library-information" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Library Information</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Catalogue <i class='bx bx-chevron-right'></i></a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a href="http://172.27.34.20:8080" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">KOHA</a></li>
                                             
                                            </ul>
                                    </ul>
                               
                                  </ul>
                        </li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="#" class="nav-link">About <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/about-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 2</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">Instructor <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/instructor-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 2</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 3</a></li>
                                        <li class="nav-item"><a routerLink="/single-instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                                        <li class="nav-item"><a routerLink="/single-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                <li class="nav-item"><a routerLink="/feedback" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Feedback</a></li>
                                <li class="nav-item"><a routerLink="/partner" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partner</a></li>
                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>
                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                                <li class="nav-item"><a routerLink="/faqs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Programs <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                
                                    <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Degree</a></li>
                                    <li class="nav-item"><a routerLink="/courses-category-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Diploma</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Short Courses <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/business" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business</a></li>
                                            <li class="nav-item"><a routerLink="/telecoms" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Technical</a></li>
                                        </ul>
                                    </li>
                                <!-- <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li> -->
                            </ul>
                        </li>

                       

                        

                       
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Faculties <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/faculty" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Faculty </a></li>
                                <li class="nav-item"><a routerLink="/partner" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners </a></li>
                                <!-- <li class="nav-item"><a routerLink="/online-motivation-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accreditations </a></li> -->
                                <li class="nav-item"><a routerLink="/language-school" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials </a></li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid Full Width</a></li>
                                <li class="nav-item"><a routerLink="/single-blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid</a></li>
                                <li class="nav-item"><a routerLink="/shop-grid-fullwidth" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid FullWidth</a></li>
                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                            </ul>

                        </li> -->
                        <li class="nav-item"><a routerLink="/tcfl-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                        <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        <!-- <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li> -->
                    </ul>
                    
                </div>
                <!-- <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ger</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Fre</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Spa</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Rus</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ita</span>
                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>01</span>
                    </a>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                </div> -->
            </nav>
        </div>
    </div> 
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>