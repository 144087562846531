<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg9">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
            </ul>
            <h2>Campuses</h2>
        </div>
    </div>
</div>

   <div class="container2">
    <div *ngFor="let campus of campuses" class="campus">
      <h2>{{ campus.name }}</h2>
      <p>{{ campus.location }}</p>
      <div class="rating">
        <span *ngFor="let _ of [1, 2, 3, 4, 5]" class="star">&starf;</span>
      </div>
      <div class="gallery">
        <div *ngFor="let image of campus.images" class="image">
          <img [src]="image" (click)="openModal(image)">
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal" [ngClass]="{ 'open': modalOpen }">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <div class="slideshow">
        <img [src]="currentImage" class="modal-image">
        <a class="prev" (click)="prevImage()">&#10094;</a>
        <a class="next" (click)="nextImage()">&#10095;</a>
      </div>
    </div>
  </div>
  

