<div class="motivation-course-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="motivation-course-banner-content">
                    <h1>Short Online Course Qualify The Best!</h1>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="motivation-course-banner-text">
                    <p>Learn from Edali online platform by following our proven ways to achieve the Best Skills for your present and future career.</p>
                    <!-- <div class="btn-box">
                        <a routerLink="/courses-2-columns-style-1" class="default-btn">
                            <i class='bx bx-move-horizontal icon-arrow before'></i>
                             <span class="label">
                                View Courses
                            </span> 
                            <i class="bx bx-move-horizontal icon-arrow after"></i>
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="motivation-course-banner-image">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=68mTG-fxzX4"
                    data-sub-html="<p>Ednuv Video</p>"
                >
                    <img src="assets/img/motivation-course/motivation-course-banner.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                        <i class="bx bx-play"></i>
                    </a>
                </a>
            </lightgallery>
        </div>
    </div>
</div>

<div class="motivation-featured-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <h2>Learn New Skills To Go Ahead For Your Career</h2>
                </div>
                <div class="col-lg-6 col-md-12">
                    <p>Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Donec sollicitudin molestie malesuada.</p>
                    <p class="bottom-zero">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-card">
                    <div class="number">01</div>
                    <h3>
                        <a routerLink="/single-courses">Short Courses</a>
                    </h3>
                    <p>Like business analytics, graphic design, Python, and more.</p>
                    <!-- <a routerLink="/single-courses" class="featured-btn">View Courses +</a> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-card">
                    <div class="number">02</div>
                    <h3>
                        <a routerLink="/single-courses">Diploma Certictaes</a>
                    </h3>
                    <p>In high-demand fields like IT, AI and cloud engineering.</p>
                    <!-- <a routerLink="/single-courses" class="featured-btn">View Courses +</a> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-card">
                    <div class="number">03</div>
                    <h3>
                        <a routerLink="/single-courses">Degree Programs</a>
                    </h3>
                    <p>From a leading university in business, computer science, and more.</p>
                    <!-- <a routerLink="/single-courses" class="featured-btn">View Courses +</a> -->
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-card">
                    <div class="number">04</div>
                    <h3>
                        <a routerLink="/single-courses">Online Courses</a>
                    </h3>
                    <p>With on-demand training and development programs.</p>
                    <!-- <a routerLink="/single-courses" class="featured-btn">View Courses +</a> -->
                </div>
            </div>
        </div>
    </div>
</div>