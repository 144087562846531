import { Component } from '@angular/core';

@Component({
  selector: 'app-online-telone-classes',
  templateUrl: './online-telone-classes.component.html',
  styleUrls: ['./online-telone-classes.component.scss']
})
export class OnlineTeloneClassesComponent {

}
