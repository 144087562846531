import { Component } from '@angular/core';

@Component({
  selector: 'app-library-information',
  templateUrl: './library-information.component.html',
  styleUrls: ['./library-information.component.scss']
})
export class LibraryInformationComponent {

}
