<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Degree</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div>
                    <h3>Telecommunications Engineering</h3>
                    <span>5 Years $600</span>
                </div>
            </div>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>