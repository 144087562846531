import { Component } from '@angular/core';

@Component({
  selector: 'app-degree-registration',
  templateUrl: './degree-registration.component.html',
  styleUrls: ['./degree-registration.component.scss']
})
export class DegreeRegistrationComponent {

}
