import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-grid-page',
  templateUrl: './shop-grid-page.component.html',
  styleUrls: ['./shop-grid-page.component.scss']
})
export class ShopGridPageComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  campuses = [
    {
      name: 'Harare Campus',
      location: 'Corner Samora Machel & Hampden Street',
      rating: 4,
      images: ['assets/img/telone/campus/img1.jpg', 'assets/img/telone/campus/img2.jpg', 'assets/img/telone/campus/img3.jpg', 
      'assets/img/telone/campus/img4.jpg', 'assets/img/telone/campus/img13.jpeg', 'assets/img/telone/campus/img14.jpeg', 'assets/img/telone/campus/img15.jpeg', 'assets/img/telone/campus/img16.jpeg' ]
    },
    // {
    //   name: 'Bulawayo Campus',
    //   location: '8 Leamington Road, Belmont',
    //   rating: 3,
    //   images: ['assets/img/telone/campus/img5.jpeg', 'assets/img/telone/campus/img6.jpeg', 'assets/img/telone/campus/img7.jpeg', 
    //   'assets/img/telone/campus/img8.jpeg', 'assets/img/telone/campus/img9.jpeg', 'assets/img/telone/campus/img10.jpeg', 'assets/img/telone/campus/img11.jpeg', 'assets/img/telone/campus/img12.jpeg' ]
    // }
  ];

  modalOpen = false;
  currentImage: string = '';
  currentIndex: number = 0;

  openModal(image: string): void {
    this.currentImage = image;
    this.modalOpen = true;
    this.currentIndex = this.campuses[0].images.indexOf(image);
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  nextImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.campuses[0].images.length;
    this.currentImage = this.campuses[0].images[this.currentIndex];
  }

  prevImage(): void {
    this.currentIndex = (this.currentIndex - 1 + this.campuses[0].images.length) % this.campuses[0].images.length;
    this.currentImage = this.campuses[0].images[this.currentIndex];
  }
}
