<app-header-style-one></app-header-style-one>

<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/content/content-image-3.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                        
                            <h3>Training Expects</h3>
                            <br>
                            <br>
                            <p>At TelOne, our team of dedicated training experts is committed to empowering individuals with the knowledge and skills they need to excel in their professional endeavors.</p>
                            <br>
                            <br>
                            <h4><b>How to apply</b></h4>
                            <br>
                             <p>
                                •	To apply for Training, one has to submit certified copies of birth, national ID and results (O’ level and A’ level where necessary) along with filled in application form.
                               </p> 
                               <p>•	The application form should be attached with proof of application fee payment
                               </p>
                               <p>
                                •	For all short courses one has to submit filled in application form with certified copy of birth and ID and O’ level result where necessary
                                </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

