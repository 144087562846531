<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Diploma</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div> -->
                    <h3>Telecommunications Engineering (Hexco)</h3>
                    <span>3 years</span>
                    <a  class="learn-more-btn">$500 <i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-camera'></i>
                    </div> -->
                    <h3>Software Engineering (Hexco) </h3>
                    <span>3 Years</span>
                    <a class="learn-more-btn">$450 <i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-layer'></i>
                    </div> -->
                    <h3>Data Science (Hexco)</h3>
                    <span>3 Years</span>
                    <a class="learn-more-btn">$450 <i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bxs-flag-checkered'></i>
                    </div> -->
                    <h3>Computer Networking (Hexco)</h3>
                    <span>3 Years</span>
                    <a  class="learn-more-btn">$450<i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-health'></i>
                    </div> -->
                    <h3>Cyber Security (Hexco)</h3>
                    <span>23 Years</span>
                    <a  class="learn-more-btn">$450 <i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-line-chart'></i>
                    </div> -->
                    <h3>Digital Marketing</h3>
                    <span>3 Years</span>
                    <a  class="learn-more-btn">$450<i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                    </div> -->
                    <h3>Financial Engineering</h3>
                    <span>3 Years</span>
                    <a  class="learn-more-btn">$450 <i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item mb-30">
                    <!-- <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div> -->
                    <h3>Business Analytics</h3>
                    <span>3 Years</span>
                    <a  class="learn-more-btn">$450<i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div> -->
                    <h3>Postgraduate Diploma in Digital Marketing</h3>
                    <span>1 Year</span>
                    <a  class="learn-more-btn">$600<i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div> -->
                    <h3>Postgraduate Diploma in Business Analytics</h3>
                    <span>1 Year</span>
                    <a  class="learn-more-btn">$600<i class='bx bx-book-reader'></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-item  mb-30">
                    <!-- <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div> -->
                    <h3>Postgraduate Diploma in Digital Financial Engineering</h3>
                    <span>1 Year</span>
                    <a  class="learn-more-btn">$600<i class='bx bx-book-reader'></i></a>
                </div>
            </div>

        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>