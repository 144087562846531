<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Technical</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70"  style="background-image: url('/assets/img/telone/partners/maths.jpg'); background-size: cover; background-position: center;">
<div class="courses-categories-area  pb-70">
    <div class="container">
        <div class="row">

            
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-plug'></i>
                        Optic Fibre
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-chip'></i>
                        Practical Electronics
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$80 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-bar-chart-alt'></i>
                        Big Data Analytics
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$80 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-link-external'></i>
                        CCNA (CISCO)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">Per Level</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$60 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-shield'></i>
                        Cyber Security Master Class
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-lock-alt'></i>
                        Cyber Security and Operations 
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-link-external'></i>
                        Gigabit Passive Optical Network (GPON)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$80 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-mobile'></i>
                        HCNA (Huawei Certified Network Associate)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">15 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-cloud'> </i>
                        Cloud Computing
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$130 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-link-external'></i>
                        ICDL Per Module/Subject
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$90 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-transfer'></i>
                        IP/PABX
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$90 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-x'></i>
                        Linux Foundation
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-code'></i>
                        Introduction to Programming
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$60 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-mobile'></i>
                        LTE (Long Term Evolution)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$130 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-shape-triangle'></i>
                        Asymmetric Digital Subscriber Line (ADSL)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$80 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bxl-python'></i>
                        Python Programming for Beginners
                        <br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$80 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bxl-python'></i>
                        Python Programming (Advanced)
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">10 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$130 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-wifi'></i>
                        Rigging Telecoms
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-sun'></i>
                        Solar Electric Power
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">5 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$100 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30" >
                    <a>
                        <i class='bx bx-globe'></i>
                        Web Intelligence
                        <br><br>
                        <span style="font-style: italic; font-size: 12px;">3 Days</span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;  ">$60 </span> -->
                      <br>
                      <br>
                    </a>
                </div>
            </div>
           
        </div>
    </div>
</div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>