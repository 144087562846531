<app-header-style-one></app-header-style-one>

<app-homeone-main-banner></app-homeone-main-banner>

<app-homeone-about></app-homeone-about>

<!-- <div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div> -->

<div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<app-homeone-courses></app-homeone-courses>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<!-- <div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p> Meet our exceptional team of instructors — passionate experts dedicated to your educational journey. With diverse backgrounds and industry experience, they bring a wealth of knowledge to each class. Join us and learn from the best for an enriching and engaging educational experience.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div> -->

<app-become-instructor-partner></app-become-instructor-partner>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <!-- <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a> -->
        </div>
        <app-blog></app-blog>
    </div>
</div>