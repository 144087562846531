<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="https://www.google.com/maps/dir//TelOne+Centre+for+Learning,+Samora+Machel+Avenue+and+Hampden+Street,+A5,+Harare/@-17.8305313,31.0134017,14.36z/data=!4m9!4m8!1m0!1m5!1m1!1s0x1931a44d632a8da7:0xf58b3cf6f6b9cd47!2m2!1d31.0216838!2d-17.8288889!3e2?entry=ttu" target="_blank">TelOne Centre for Learning, Corner Hampden, Samora Machel, Belvedere, Harare</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+263242780126">024 278 0126</a>
                        </li>
                        <i class='bx bx-twitter'></i>
                        <a href=" https://twitter.com/TelOnezw"></a>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:studentaffairs@telone.co.zw">studentaffairs&#64;telone.co.zw</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href=" https://www.facebook.com/TelOneCFL" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href=" https://twitter.com/TelOnezw" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Department</h3>
                    <!-- <ul class="support-link">
                        <li><a routerLink="/">Student Affairs</a></li>
                        <li><a routerLink="/">Reception</a></li>
                        <li><a routerLink="/">Accounts</a></li>
                        <li><a routerLink="/">Library</a></li>
    
                    </ul> -->
                    <ul class="support-link">
                        <li><a>Student Affairs</a></li>
                        <li><a>Reception</a></li>
                        <li><a>Accounts</a></li>
                        <li><a>Library</a></li>
    
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Details</h3>
                    <ul class="useful-link">
                        <li><a>0242 780134 / 0242 753169</a></li>
                        <!-- <li><a routerLink="tel:+263242780126">024 278 0126</a></li> -->
                        <li><a>071 978 0128 / 071 978 0129</a></li>                    
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Banking details</h3>
                    <div class="newsletter-box">
                        <p>Account Name: TelOne</p>
                        <p>Bank: BANC ABC</p>
                        <p>Branch: Mt Pleasant</p>
                        <p>ZWG Account Number: 10109163902015</p>
                        <p>NOSTR Account Number: 10109166633189</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
