import { Component } from '@angular/core';

@Component({
  selector: 'app-semester-timetable',
  templateUrl: './semester-timetable.component.html',
  styleUrls: ['./semester-timetable.component.scss']
})
export class SemesterTimetableComponent {

}
