<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Admissions</li>
            </ul>
            <h2>Calendar</h2>
        </div>
    </div>
</div>
<div class="calendar">
    <h1 style="margin-top: 30px; text-align: center; color: rgb(243, 24, 24);">Semester Calendar 2024!</h1>
    <p>
        Welcome to the semester calendar for 2024! Below you'll find important dates, deadlines, and events for the current academic term. Please refer to this calendar regularly to stay updated on key academic and administrative information.
    </p>
    <div class="word">
      <iframe src="/assets/pdf/AcademicCalendar2024..pdf" width="100%" height="600px"></iframe>
  </div>
</div>
