<app-header-style-one></app-header-style-one>
<div class="container">
    <div class="koha">
        <h1>Koha Library Software</h1>
        <h3>The world's first free and open source library system</h3>
        <p>Koha is a fully featured, scalable library management system. Development is sponsored by libraries of varying types and sizes, volunteers, and support companies worldwide.</p>
        <p> 
            Click the link to open <a href="http://172.27.34.20:8080/" class="hover-underline" target="_blank">Koha</a>
        </p>
        </div>
        <div class="img">
        <img src="/assets/img/telone/campus/img3.jpg" alt="">
        </div>
</div>

   
