<app-header-style-three></app-header-style-three>

<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <span class="sub-title">About Us</span>
                    <h2>Learn New Skills to go ahead for Your Career</h2>
                    <h6>We can support student forum 24/7 for national and international students.</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p>
                    </div>
                    <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/about-us.jpg" alt="image">
                    <img src="assets/img/about/600X485.jpg" alt="image">
                    <div class="text-box">
                        <div class="inner">
                            Trusted By
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-our-mission></app-our-mission>

<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Through dynamic teaching methods and interactive engagement, our instructors create an inclusive and supportive learning environment where every student feels valued and motivated to excel. Whether it's in the classroom, online, or through hands-on workshops, our team goes above and beyond to cultivate a culture of collaboration, critical thinking, and lifelong learning.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>