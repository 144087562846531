<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg4"  >
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Diploma</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70" style="background-image: url('/assets/img/telone/partners/maths.jpg'); background-size: cover; background-position: center;">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-font-family'></i>
                        Computer Networking
                        <br>

                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                        </div>
                        
                        
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bxs-drink'></i>
                        Cyber Security
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                            <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                        </div>    
                    </a>
                   
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-first-aid'></i>
                        Data Science
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                        </div>
                        
                        
                    </a>
                </div>
            </div>
         
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-briefcase-alt-2'></i>
                        Digital Marketing
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                            <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-bar-chart-alt-2'></i>
                         Financial Engineering
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                        </div>
                        
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-book-reader'></i>
                        Software Engineering
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span><br>
                        <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                       
                        </div>
                        
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-courses-category mb-30">
                    <a >
                        <i class='bx bx-target-lock'></i>
                        Telecommunication Engineering
                        <br>
                        <div class="container" style="padding-top: 32px;">
                            <span style="font-style: italic; font-size: 12px;">3 Years </span>
                            <!-- <span style="font-style: italic; font-size: 12px;">$635/SEM </span> -->
                         
                        </div>
                      
                    </a>
                </div>
            </div>
          
           
       
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>