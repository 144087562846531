<app-header-style-one></app-header-style-one>

<app-hometen-main-banner></app-hometen-main-banner>

<app-language-category></app-language-category>

<app-why-choose-us></app-why-choose-us>

<app-homeeight-courses></app-homeeight-courses>

<app-funfacts-style-two></app-funfacts-style-two>

<app-students-feedback></app-students-feedback>

<app-how-to-apply></app-how-to-apply>

<app-homeeight-events></app-homeeight-events>


