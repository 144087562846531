<div class="testimonials-slides">
    <owl-carousel-o [options]="testimonialsSlides">
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>The guidance and support I received from the instructors at this college have been invaluable in shaping my academic journey. Their dedication to student success goes beyond the classroom, inspiring me to strive for excellence in every aspect of my education.</p>
                <div class="info">
                    <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image">
                    <h3>John Smith</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>The welcoming atmosphere and personalized attention I experienced here made me feel right at home. The instructors not only impart knowledge but also encourage critical thinking and creativity, empowering me to pursue my passions and achieve my goals.</p>
                <div class="info">
                    <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Attending this college has been a transformative experience for me. The instructors truly care about their students' growth and development, providing mentorship and guidance that extend far beyond the curriculum. I am grateful for the opportunities and support I've received here.</p>
                <div class="info">
                    <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
                    <h3>David Warner</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>I couldn't have asked for a better learning environment than what I found at this college. The instructors are not just educators; they are mentors who challenge and inspire students to reach their full potential. Thanks to their encouragement and expertise, I feel confident and prepared for whatever lies ahead.</p>
                <div class="info">
                    <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image">
                    <h3>James Anderson</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>