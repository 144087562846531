<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Application Form</li>
            </ul>
            <h2>POST-GRAD</h2>
        </div>
    </div>
</div>
<img src="assets/img/telone/p1.png" alt="image">
<img src="assets/img/telone/p2.png" alt="image">
<img src="assets/img/telone/p3.png" alt="image">
<img src="assets/img/telone/p4.png" alt="image">

