<section class="feedback-area-two pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Our Students Feedback</h2>
        </div>
        <div class="feedback-slides">
            <owl-carousel-o [options]="studentsFeedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>
                            Learning a new language was a dream, and Telone Learning Center made it a reality. The language courses were immersive, and the live sessions with native speakers enhanced my fluency. The cultural exchange programs added an extra layer to my learning experience. Telone truly opened doors to a new world for me!
                        </p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <!-- <img src="assets/img/user1.jpg" alt="image"> -->
                                <div class="title">
                                    <h3>Tatenda Muchemwa</h3>
                                    <span>Software  Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>
                            Telone Learning Center empowered me to turn my business idea into a reality. The entrepreneurship courses provided practical insights, and the mentorship program connected me with experienced professionals. Today, I'm running my own successful business, and I credit Telone for equipping me with the skills and knowledge needed to thrive in the business world.
                        </p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="title">
                                    <h3>Grace Kusotera</h3>
                                    <span>Business Analyst</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>Enrolling at Telone Learning Center was a turning point in my academic journey. The engaging courses and dedicated instructors created an environment where I could thrive. The interactive online platform made learning enjoyable, and I've seen a significant improvement in my grades. Thank you, Telone, for making education accessible and enjoyable!</p>
                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="title">
                                    <h3>Esalencia Munetsi</h3>
                                    <span>Financial Engineer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="feedback-image">
        <img src="assets/img/feedback-img.jpg" alt="image">
    </div>
</section>