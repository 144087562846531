<div class="offer-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>What We Offer For Growth</h2>
            <p> Discover limitless possibilities for growth with our comprehensive learning programs. We offer a diverse range of courses led by industry experts, designed to empower individuals at every stage of their educational journey. From skill-building workshops to advanced courses, our offerings cater to diverse interests and career aspirations. Embrace a dynamic learning environment that fosters innovation, critical thinking, and personal development. </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3>Exclusive Advisor</h3>
                    <p> Explore boundless knowledge at 'Exclusive Advisor' – your trusted hub for personalized learning. Elevate your skills with expert-guided courses tailored to your unique educational journey</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bx-target-lock'></i>
                    </div>
                    <h3>Reached Your Goals</h3>
                    <p>Unlock your potential with personalized courses designed to help you achieve your goals efficiently and effectively.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-offer-box">
                    <div class="icon">
                        <i class='bx bxs-thermometer'></i>
                    </div>
                    <h3>Digital Laboratory</h3>
                    <p>Step into the future at 'Digital Laboratory' – where innovative learning meets technology. Unleash your potential through cutting-edge courses, blending digital expertise with hands-on exploration.</p>
                </div>
            </div>
        </div>
    </div>
</div>