import { Component } from '@angular/core';

@Component({
  selector: 'app-telecommunication-courses',
  templateUrl: './telecommunication-courses.component.html',
  styleUrls: ['./telecommunication-courses.component.scss']
})
export class TelecommunicationCoursesComponent {

}
