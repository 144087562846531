import { Component } from '@angular/core';

@Component({
  selector: 'app-business-courses',
  templateUrl: './business-courses.component.html',
  styleUrls: ['./business-courses.component.scss']
})
export class BusinessCoursesComponent {

}
