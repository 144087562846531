import { Component } from '@angular/core';

@Component({
  selector: 'app-tcfl-calendar',
  templateUrl: './tcfl-calendar.component.html',
  styleUrls: ['./tcfl-calendar.component.scss']
})
export class TcflCalendarComponent {

}
